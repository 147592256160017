import cn from "classnames";
import React from 'react';
import { observer } from "mobx-react-lite";

import styles from './footer.module.scss';


const Footer = () => {

    return (
        <div className="styles_footer__l17aE">
            <a href="https://www.youtube.com/embed/zeIHBDyf7J0"
               className="styles_block__3lS7T"
               target="_blank"
               rel="noreferrer">
                <svg viewBox="0 0 32 24" fill="none" className="styles_icon__3-fSI styles_youtube__31A4Z">
                    <path d="M31.9964 12.6244C31.9875 13.3978 31.962 14.1629 31.92 14.9198C31.8667 15.7198 31.7933 16.5598 31.7 17.4398C31.6067 18.3198 31.48 19.0665 31.32 19.6798C31.1333 20.3731 30.7867 20.9731 30.28 21.4798C29.7733 21.9865 29.1733 22.3331 28.48 22.5198C27.8667 22.6798 26.8733 22.8065 25.5 22.8998C24.1267 22.9931 22.7333 23.0665 21.32 23.1198C19.6667 23.1731 17.8933 23.1998 16 23.1998H14.36C13.8533 23.1998 13.2733 23.1931 12.62 23.1798C11.9667 23.1665 11.28 23.1465 10.56 23.1198C10.5685 23.1007 10.5769 23.0815 10.5853 23.0623C9.2044 23.0093 7.84264 22.9371 6.5 22.8459C5.12667 22.7525 4.13333 22.6259 3.52 22.4659C2.82667 22.2792 2.22667 21.9325 1.72 21.4259C1.21333 20.9192 0.866667 20.3192 0.68 19.6259C0.52 19.0125 0.393333 18.2659 0.3 17.3859C0.206667 16.5059 0.133333 15.6659 0.08 14.8659C0.0398977 14.144 0.0148723 13.4147 0.00492361 12.6777C0.00328226 12.6784 0.00164106 12.6791 0 12.6798V11.9998C0 11.0131 0.026668 10.0398 0.0800013 9.07981C0.133335 8.27981 0.206669 7.43981 0.300002 6.55981C0.393336 5.67981 0.52 4.93314 0.68 4.31981C0.866667 3.62647 1.21333 3.02647 1.72 2.5198C2.22666 2.01314 2.82667 1.66647 3.52 1.4798C4.13333 1.3198 5.12667 1.19314 6.5 1.0998C7.87333 1.00647 9.26667 0.933138 10.68 0.879805C12.3333 0.826471 14.1067 0.799805 16 0.799805C17.8933 0.799805 19.6667 0.826471 21.32 0.879805C22.7333 0.933138 24.1267 1.00647 25.5 1.0998C26.8733 1.19314 27.8667 1.3198 28.48 1.4798C29.1733 1.66647 29.7733 2.01314 30.28 2.5198C30.7867 3.02647 31.1333 3.62647 31.32 4.31981C31.48 4.93314 31.6067 5.67981 31.7 6.55981C31.7933 7.43981 31.8667 8.27981 31.92 9.07981C31.9733 10.0398 32 11.0131 32 11.9998V12.6259C31.9988 12.6254 31.9976 12.6249 31.9964 12.6244Z" fill="#0000003D"/>
                    <path d="M12.8008 16.7455L21.1208 11.9455L12.8008 7.14551V16.7455Z" fill="white"/>
                </svg>
                <span>Как оплатить</span>
            </a>
        </div>
    );

};

export default observer(Footer);
