import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import Loading from "@components/loading/Loading";

const Main = React.lazy(() => import('@views/main/main'));
const Payment = React.lazy(() => import('@views/payment/payment'));
const PaymentMethods = React.lazy(() => import('@views/payment-methods/payment-methods'));
const UserId = React.lazy(() => import('@views/user-id/user-id'));
const Wait = React.lazy(() => import('@views/wait/wait'));
const Requisites = React.lazy(() => import('@views/requisites/requisites'));
const Canceled = React.lazy(() => import('@views/canceled/canceled'));
const Offline = React.lazy(() => import('@views/offline/offline'));
const NotFound = React.lazy(() => import('@layouts/not-found/NotFound'));
const Payout = React.lazy(() => import('@views/payout/payout'));
const Instructions = React.lazy(() => import('@views/instructions/instructions'));

const MainRoutes = () => {
    return (
        <Suspense fallback={<Loading/>}>
            <Routes>
                <Route index element={<Navigate to="/payment" />} />
                <Route path={"/main"} element={<Navigate to="/payment" />} />
                <Route path={"/instructions"} element={<Instructions />} />
                <Route path={"/payment"} element={<Payment />} />
                <Route path={"/payment/rub/:sum"} element={<Payment />} />
                <Route path={"/payment/:id"} element={<Payment />} />
                <Route path={"/payment/:id/:sum"} element={<Payment />} />
                <Route path={"/payment-methods/:id"} element={<PaymentMethods />} />
                <Route path={"/user-id/:id"} element={<UserId />} />
                <Route path={"/wait/:id"} element={<Wait />} />
                <Route path={"/requisites/:id"} element={<Requisites />} />
                <Route path={"/canceled/:id"} element={<Canceled />} />
                <Route path={"/offline"} element={<Offline />} />
                <Route path={"/payout"} element={<Payout />} />
                <Route path='*' element={<NotFound />} />
            </Routes>
        </Suspense>
    );
}

export default observer(MainRoutes);